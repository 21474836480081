import { Nav, Navbar } from "react-bootstrap"
import React from "react"
import styled from "@emotion/styled"

const NavBar = () => {
  const Logo = styled.h1`
    /* height: 70px; */
    /* display: none; */
    /* width:50%; */

    /* h1 { */
      color: green;
      text-align: left;
      text-transform: none;
    /* } */

    @media(max-width:768px) {
      display: block !important;
    }
  `;

  const UpperLink = styled(Nav.Link)`
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
  `;

  const DarkBar = styled(Navbar)`
    background-color: rgba(102, 66, 66, .9);
    /* display: flex;
    justify-content: flex-start; */
    min-height: 100px;
    padding: 0 10%;

    @media(min-width: 991px) {
      justify-content: flex-end;
    }
  `;

  const NavLinkArea = styled(Navbar.Collapse)`
    width: 80%;

    @media(min-width: 991px) {
      /* position: fixed; */
    }
  `

  return (
    <DarkBar className="" collapseOnSelect fixed="sticky" expand="lg" variant="dark">
      <Navbar.Brand href="/" className="py-0" style={{}}>
        <Logo>DamuNFT</Logo>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <NavLinkArea className="justify-content-end" id="responsive-navbar-nav">
        <Nav id="home-nav">
          <UpperLink href="/#intro">About</UpperLink>
          <UpperLink href="/#benefits">Benefits</UpperLink>
          <UpperLink href="/#roadmap">Roadmap</UpperLink>
          <UpperLink href="/#team">Team</UpperLink>
        </Nav>
        <Nav id="social-links" className="ml-5">
          <UpperLink href="https://t.me/"><i className="fa fa-telegram" aria-hidden="true"></i></UpperLink>
          <UpperLink href="https://discord.gg/"><i className="fa fa-discord-alt social-icon pr-lg-0"></i></UpperLink>
          <UpperLink href="https://twitter.com/"><i className="fa fa-twitter social-icon pr-lg-0"></i></UpperLink>
        </Nav>
      </NavLinkArea>
    </DarkBar>
  )
}

export default NavBar;
